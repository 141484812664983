<template>
  <v-container fluid v-if="!isLoading">
    <v-form ref="form" v-model="validForm">
      <v-card>
        <v-card-title class="white--text primary py-2" primary-title>
          <div>
            <h3 class="headline mb-0">{{ titulo }}</h3>
          </div>
        </v-card-title>
        <v-card-text>
          <!-- BOTONES -->
          <v-row>
            <v-col cols="12">
              <v-btn
                class="float-right"
                color="primary"
                dark
                @click.prevent="guardarDatos"
                >GUARDAR</v-btn
              >
              <v-btn
                class="float-right mr-2"
                color="primary"
                dark
                :to="{ name: 'guias' }"
                >CANCELAR</v-btn
              >
            </v-col>
          </v-row>

          <!-- texto si no es valido el form -->
          <v-row>
            <v-col cols="12">
              <div class="py-1" ref="alert">
                <v-alert
                  type="error"
                  v-show="mostrarAlert"
                  transition="scale-transition"
                >
                  Debe seleccionar al menos una provincia, código postal o CCAA
                </v-alert>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <!-- TEXT NOMBRE ZONA -->
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                clearable
                dense
                v-model="datos.zona"
                name="filtro"
                label="Zona"
                autocomplete="OFF"
                :rules="[(v) => !!v || 'Este campo es requerido']"
                required
              ></v-text-field>
            </v-col>

            <!-- SELECT AGRUPAR S/N -->
            <v-col cols="12" md="3">
              <v-select
                :items="selectAgruparProv"
                v-model="datos.agrupar_provincia"
                item-value.number="value"
                item-text="texto"
                outlined
                dense
                label="Agrupar Provincias"
              ></v-select>
            </v-col>

            <!-- SELECT TIPO DE GUIA -->
            <v-col cols="12" md="3">
              <v-select
                :items="tipoGuia"
                v-model="datos.tipo_guia_id"
                item-value="id"
                item-text="tipo"
                outlined
                dense
                label="Tipo Guia"
                required
                :rules="[(value) => !!value || 'Escoga un tipo']"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <!-- COLUMNA IZQ - SELECTOR DE ZONAS -->
            <v-col cols="12" md="6">
              <v-card>
                <v-card-title primary-title>
                  Selector de zonas
                </v-card-title>
                <v-card-text>
                  <!-- SELECTOR DE PROVINCIAS -->
                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        outlined
                        dense
                        multiple
                        chips
                        :items="compProvincias"
                        item-text="provincia"
                        v-model="provincesSelected"
                        label="Provincia"
                        :menu-props="{
                          closeOnContentClick: true,
                          offsetY: true,
                        }"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="toggleProvinces">
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  provincesSelected.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                                >{{ iconProv }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >Seleccionar todas</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item }">
                          <v-chip
                            color="success"
                            :small="$vuetify.breakpoint.smAndUp"
                            :x-small="$vuetify.breakpoint.xsOnly"
                            close
                            @click:close="eliminarChipProv(item)"
                          >
                            <span>{{ item.provincia }}</span>
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <!-- SELECTOR DE CCAA -->
                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        outlined
                        dense
                        multiple
                        chips
                        :items="compCCAA"
                        item-text="ccaa"
                        v-model="ccaaSelected"
                        label="CCAA"
                        :menu-props="{
                          closeOnContentClick: true,
                          offsetY: true,
                        }"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="toggleCCAA">
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  provincesSelected.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                                >{{ iconCCAA }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >Seleccionar todas</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item }">
                          <v-chip
                            close
                            class="white--text"
                            color="orange"
                            :small="$vuetify.breakpoint.smAndUp"
                            :x-small="$vuetify.breakpoint.xsOnly"
                            @click:close="eliminarChipCCAA(item)"
                          >
                            <span>{{ item.ccaa }}</span>
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- LISTA DE COD POSTALES ELEGIDOS -->
            <v-col cols="12" md="6">
              <v-card>
                <v-card-title class="font-weight-black body-1">
                  Cod Postal | Población | Provincia
                </v-card-title>

                <v-card-text class="pt-0 pb-1 px-1">
                  <!-- SELECTOR DE CODPOSTALES POBLACIONES -->
                  <v-row dense>
                    <v-col cols="12">
                      <zipcodes-desp
                        @emitSeleccion="selectedZip"
                        :selected="{ read_idcp: null }"
                        :requerido="true"
                        :emitirRegistro="true"
                        :zipCodes="zipCodesAll"
                      ></zipcodes-desp>
                    </v-col>
                  </v-row>
                  <v-simple-table dense v-if="zipCodesSelected.length > 0">
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="zc in zipCodesSelected" :key="zc.zico_id">
                          <td>
                            {{ zc.zico_code }} - {{ zc.zico_town }} ({{
                              zc.zico_province
                            }})
                          </td>
                          <td>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  icon
                                  color="red"
                                  class="float-right"
                                  @click="eliminarZipCode(zc)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- TEST -->
          <v-row>
            <v-col cols="12" md="5"> </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";

import ZipcodesDesp from "@/components/comun/ZipcodesDespGuia";
export default {
  name: "Guia-Form",
  components: {
    ZipcodesDesp,
  },
  data: () => ({
    titulo: "Nueva Guia",
    validForm: true,
    zipCodeFoco: null,
    isLoading: true,
    datos: {
      id: null,
      zona: null,
      tipo_guia_id: null,
      agrupar_provincia: 0,
    },
    tmpCode: null,
    zipCodesSelected: [],
    provincesSelected: [],
    ccaaSelected: [],
    selectAgruparProv: [
      { value: 0, texto: "No" },
      { value: 1, texto: "Sí" },
    ],
    provList: [],
    zipCodesAll: [],
    tipoGuia: [],
  }),
  methods: {
    ...mapActions("guias", ["setDatosGuias", "setNuevaGuia"]),
    /* funcion para guardar los datos en la api */
    guardarDatos() {
      this.$refs.form.preventDefault;

      this.$refs.form.validate();

      const datos = {
        zip_codes: this.zipCodesSelected,
        provinces: this.provincesSelected,
        ccaa: this.ccaaSelected,
        datos: this.datos,
      };

      if (this.mostrarAlert) {
        const tmp = this.$refs.alert.classList;
        tmp.add("animated", "shake");
        setTimeout(() => {
          tmp.remove("animated", "shake");
        }, 1500);
      }

      if (!this.validForm || this.mostrarAlert) {
        return;
      }

      this.setNuevaGuia(datos).then((res) => {
        console.log(res);
      });

      console.log(datos);
    },
    /* funcion para seleccionar el foco del raton */
    cogerFoco(id) {
      this.zipCodeFoco = id;
    },
    /* funcion que viene del selector de CP */
    selectedZip(objZip) {
      const idx = this.zipCodesSelected.findIndex(
        (z) => z.zico_id === objZip.zico_id
      );
      if (idx > -1) {
        console.log("existe");
        return;
      }
      this.zipCodesSelected.push(Object.assign({}, objZip));
      console.log("en el parent", objZip, this.zipCodesSelected);
    },
    /* funcion para sel/desel el selector CCAA */
    toggleCCAA() {
      this.$nextTick(() => {
        if (this.ccaaSelected.length === this.compCCAA.length) {
          this.ccaaSelected = [];
        } else {
          this.ccaaSelected = this.compCCAA.map((c) => c.ccaa);
        }
      });
    },
    /* funcion para des/sel el selector de provincias */
    toggleProvinces() {
      this.$nextTick(() => {
        if (this.provincesSelected.length === this.compProvincias.length) {
          this.provincesSelected = [];
        } else {
          this.provincesSelected = this.compProvincias.map((c) => c.provincia);
        }
      });
    },
    /* funcion para eliminar desde la etiqueta el item de CCAA*/
    eliminarChipCCAA(item) {
      const idx = this.ccaaSelected.findIndex((c) => c === item.ccaa);
      this.ccaaSelected.splice(idx, 1);
    },
    /* funcion para eliminar desde la etiqueta el item de provincias*/
    eliminarChipProv(item) {
      const idx = this.provincesSelected.findIndex((c) => c === item.provincia);
      this.provincesSelected.splice(idx, 1);
    },
    /* funcion para eliminar un zipcode de la lista */
    eliminarZipCode(item) {
      const idx = this.zipCodesSelected.findIndex(
        (z) => z.zico_id === item.zico_id
      );
      this.zipCodesSelected.splice(idx, 1);
    },
  },
  computed: {
    /* compMostrar Alert */
    mostrarAlert() {
      if (this.isLoading) return false;
      if (
        this.provincesSelected.length === 0 &&
        this.ccaaSelected.length === 0 &&
        this.zipCodesSelected.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    /* icono de provincias selector */
    iconProv() {
      const provSelected = this.provincesSelected.length;
      const provTotal = this.compProvincias.length;
      if (provSelected === provTotal) return "mdi-close-box";
      if (provSelected > 0 && provSelected !== provTotal)
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    /* icono de CCAA selector */
    iconCCAA() {
      const ccaaSelected = this.ccaaSelected.length;
      const ccaaTotal = this.compCCAA.length;
      if (ccaaSelected === ccaaTotal) return "mdi-close-box";
      if (ccaaSelected > 0 && ccaaSelected !== ccaaTotal)
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    /* LISta de provincias */
    compProvincias() {
      if (!this.provList) {
        return [];
      }
      const sal = this.provList.reduce((acc, curr) => {
        acc.push({ id: curr.id, provincia: curr.provincia });
        return acc;
      }, []);
      return _.orderBy(sal, ["provincia"]);
    },
    /* lista de comunidades autónomas */
    compCCAA() {
      if (!this.provList) {
        return [];
      }
      const ccaaUniq = _.uniqBy(this.provList, "ccaa");
      const sal = ccaaUniq.reduce((acc, curr, idx) => {
        acc.push({ id: idx, ccaa: curr.ccaa });
        return acc;
      }, []);
      return _.orderBy(sal, ["ccaa"]);
    },
  },
  async created() {
    const tmp = await this.setDatosGuias();

    this.provList = tmp.provincias;
    this.zipCodesAll = tmp.zipcodes;
    this.tipoGuia = tmp.tipo_guia;
    this.isLoading = false;
  },
  mounted() {},
};
</script>

<style scoped>
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
</style>
